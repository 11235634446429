import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { getImage } from 'gatsby-plugin-image';

import { recipeCategoryIcon, iconImg } from './recipe-card.module.scss';

import { IRecipe } from '../../models/recipe.model';
import prependStrapiUrl from '../../utils/prepend-strapi-url';

import ListingCard from './listing-card';

interface IRecipeCard {
    readonly data: IRecipe;
    type?: 'normal' | 'slider';
    showSummary?: boolean;
    fullWidth: boolean;
    asGatsbyImage?: boolean;
}

const RecipeCard: React.FC<IRecipeCard> = ({
    data,
    type = 'normal',
    showSummary = true,
    fullWidth,
    asGatsbyImage = true,
}) => {
    const { title, description, mainImage, recipeSummary, slug, recipeCategories } = data;
    const { t } = useI18next();

    const recipeCategory = recipeCategories?.filter((category) => !category.isRoot)?.[0];

    const ratioImageProps = asGatsbyImage
        ? { image: mainImage?.localFile && getImage(mainImage.localFile) }
        : { src: prependStrapiUrl(mainImage?.formats?.small?.url || mainImage.url) };

    return (
        <>
            <ListingCard
                data={{
                    title: title,
                    description: description,
                    recipeSummary: recipeSummary,
                    redirectLink: slug,
                    buttonText: t('recipe.card.link'),
                    ...ratioImageProps,
                    imageAlt: mainImage?.alternativeText,
                }}
                type={type === 'slider' ? 'slider' : 'normal'}
                showSummary={showSummary}
                fullWidth={fullWidth}
            >
                {type !== 'slider' && recipeCategory?.icon && (
                    <div className={recipeCategoryIcon}>
                        <img
                            className={iconImg}
                            src={prependStrapiUrl(recipeCategory.icon.url)}
                            alt={recipeCategory.icon.alternativeText}
                        />
                    </div>
                )}
            </ListingCard>
        </>
    );
};

export default RecipeCard;
