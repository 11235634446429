import React from 'react';

import {
    listing,
    listingLayout,
    pagination,
    largeGaps as largeGapsClass,
} from './listing.module.scss';
import { grid } from '../../styles/grid.module.scss';

import Pagination from '../molecules/pagination';

interface IListing {
    className?: string;
    columns?: number;
    ItemComponent: React.ElementType;
    data: Record<string, unknown>[];
    itemIdKey?: string;
    paginationLinks?: string[];
    itemProps?: Record<string, unknown>;
    largeGaps?: boolean;
    paginationConfig?: {
        links: {
            to: string;
            params?: {
                key: string;
                value: string;
            }[];
        }[];
        activeIndex: number;
    };
}

const Listing: React.FC<IListing> = ({
    className = '',
    columns = 3,
    ItemComponent,
    data,
    itemIdKey = 'id',
    paginationConfig,
    itemProps,
    largeGaps = false,
}) => {
    return (
        <div className={`${listing} ${grid} ${className}`}>
            <div
                className={`${listingLayout} ${largeGaps ? largeGapsClass : ''}`}
                data-columns={columns}
            >
                {data.map((item) => {
                    return <ItemComponent data={item} key={item[itemIdKey]} {...itemProps} />;
                })}
            </div>
            {paginationConfig?.links && paginationConfig?.links.length > 0 && (
                <Pagination
                    className={pagination}
                    links={paginationConfig.links}
                    range={2}
                    activeIndex={paginationConfig.activeIndex}
                />
            )}
        </div>
    );
};

export default Listing;
