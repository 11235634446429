import React from 'react';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { graphql } from 'gatsby';

import { layout, header } from './recipe-listing.module.scss';

import { IQueryAllResult } from '../models/query-all-result.model';
import { IRecipe } from '../models/recipe.model';
import { ISitePageContext } from '../models/site-page.model';
import { IMeta } from '../models/meta.model';
import { IRecipeTag } from '../models/recipe-tag.model';
import { IBreadcrumbsProps } from '../models/breadcrumbs.model';

import { getNodes } from '../utils/get-nodes';

import MainLayout from '../layouts/main-layout';
import Listing from '../components/organisms/listing';
import RecipeCard from '../components/molecules/recipe-card';
import BlockHeader from '../components/molecules/block-header';

interface ITagListing {
    readonly data: {
        allStrapiRecipe: IQueryAllResult<IRecipe>;
        allStrapiRecipeTag: IQueryAllResult<IRecipeTag>;
        meta: IMeta;
        pageBreadcrumbs: {
            breadcrumbs: IBreadcrumbsProps[];
        };
    };
    readonly pageContext: ISitePageContext;
    serverData: {
        recipes: IRecipe[];
        showRecipesFromServerData: boolean;
    };
}

const TagListing: React.FC<ITagListing> = ({ data, pageContext }: ITagListing) => {
    const { allStrapiRecipe, meta } = data;
    const { paginationKeys } = pageContext;
    const { pageKey } = useI18next();
    const activeIndex = paginationKeys.indexOf(pageKey);
    const page = { meta };

    const recipes = getNodes(allStrapiRecipe);

    return (
        <MainLayout
            className={layout}
            page={page}
            showRecipesSlider={false}
            includeHeaderPadding={true}
            showLocalizationBar={true}
            breadcrumbs={data.pageBreadcrumbs.breadcrumbs}
        >
            <BlockHeader className={header} titleText={pageContext.translations.pl.label} />
            <Listing
                largeGaps={true}
                columns={4}
                ItemComponent={RecipeCard}
                data={recipes}
                paginationConfig={{
                    links: paginationKeys.map((key) => {
                        return { to: key };
                    }),
                    activeIndex: activeIndex,
                }}
                itemIdKey="recipeId"
                itemProps={{
                    fullWidth: true,
                    asGatsbyImage: true,
                }}
            />
        </MainLayout>
    );
};

export const query = graphql`
    query ($language: String!, $metaId: Int, $path: String, $recipeId: [Int]) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        pageBreadcrumbs: sitePage(path: { eq: $path }) {
            breadcrumbs {
                label
                slug
            }
        }
        allStrapiRecipe(
            filter: { recipeId: { in: $recipeId }, locale: { eq: $language } }
            sort: { fields: [title], order: [ASC] }
        ) {
            edges {
                node {
                    ...recipeCardFields
                }
            }
        }
        meta: strapiMeta(metaId: { eq: $metaId }) {
            ...metaFields
        }
    }
`;

export default TagListing;
